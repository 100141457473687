.popup-arrow {
    color: rgb(255, 255, 255);
}

.popup-content {
    width: 500px;
    border: none !important;
    border-radius: 8px;
    background: white;
}

[role='tooltip'].popup-content {
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
}

[data-popup='tooltip'].popup-overlay {
    background: transparent;
}

.modal {
    font-size: 12px;
    font-family: "Inter", sans-serif;
}

.modal > .header {
    width: 100%;
    font-size: 18px;
    text-align: center;
    padding: 5px;
}

.modal > .content {
    width: 100%;
    padding: 48px 48px 0 48px;
}

.modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}

.modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
}

.credit-widget {
    margin-bottom: 24px;
}

.credit-widget .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
}

.credit-widget .value {
    font-size: 14px;
}

.credit-widget .value a {
    display: inline-flex;
    align-items: center;
    margin-bottom: 4px;
    text-decoration: none;
    color: gray;
}

.credit-widget .source-link {
    display: block !important;
}

.credit-widget .value a:hover {
    color: black;
}

.credit-widget .value a img {
    margin-right: 8px;
}

.credit-widget .as-link {
    border: solid 1px black;
    color: black;
    padding: 8px;
    text-decoration: none;
}

.credits-content hr {
    border: none;
    height: 1px;
    background: black;
    margin-bottom: 24px;
}