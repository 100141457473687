*, ::after, ::before {
    box-sizing: border-box
}

body {
    background: #C9D6FF; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #E2E2E2, #C9D6FF); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #E2E2E2, #C9D6FF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.App {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "slnt" 0;
    letter-spacing: -0.25px;
    user-select: none;
}

a {
    outline: none;
}

.main-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 16px;
    border-radius: 8px;
    text-align: center;
}

.main-content .title {
    font-size: 24px;
    font-weight: 700;
}

.mt-15 {
    margin-top: 15px;
}

.text-left {
    text-align: left;
}

.winter, .spring, .summer, .default {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 497px;
    height: 359px;
    box-shadow: -2px 6px 9.3px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    padding: 48px;
    transition: 0.3s
}

.winter, .default {
    background: linear-gradient(328.17deg, #0669CB 12.95%, #00F7FF 87.05%);
}

.spring {
    background: linear-gradient(328.17deg, #CBA306 12.95%, #80FF00 87.05%);
}

.summer {
    background: linear-gradient(328.17deg, #AC2709 12.95%, #FF7354 87.05%)
}

.location {
    font-size: 40px;
    letter-spacing: -1px;
    text-align: left;
}

.winter .location, .default .location {
    color: #2D7291;
}

.spring .location {
    color: #2D7291;
}

.summer .location {
    color: #5C281D;
}

.weather-data {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.main-content .celcius {
    font-weight: 500;
    color: #FFF;
    opacity: 0.50;
    font-size: 20px;
}

.main-content .no-after {
    margin-left: 5px;
}

.main-content .current-temp {
    font-size: 130px;
    font-weight: 800;
    letter-spacing: -6px;
    color: #FFF;
}

.main-content .after-dot {
    font-weight: 500;
    color: #EFD5D5;
    font-size: 64px;
}

.details-button {
    display: flex;
    align-items: center;
    color: white;
    cursor: pointer;
    right: 48px;
    position: absolute;
    bottom: 48px;
    font-weight: 600;
}

.details-button .icon {
    margin-right: 8px;
}

.details {
    display: flex;
    flex-wrap: wrap;
}

.detail-widget {
    margin: 0 24px 38px 0;
    color: white;
    text-align: left;
    width: calc(33.333% - 24px);
}

.detail-widget .detail-title {
    margin: 0 0 12px 0;
    font-size: 16px;
    min-height: 40px;
    color: #E1DADA
}

.detail-widget .detail-value {
    font-weight: 700;
    font-size: 25px;
    color: white;
}

.detail-widget span {
    font-weight: 400;
    margin-left: 3px;
    font-size: 12px;
    opacity: 0.80;
}

.spring .detail-title {
    color: #2D7291;
}

.spring .value {
    color: #2D7291;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    color: #888888;
    font-size: 12px;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
}

.footer-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-left a {
    display: flex;
    align-items: center;
    color: #888888;
    font-size: 12px;
    text-decoration: none;
}

.footer-left a img {
    margin-right: 8px;
}

.footer-left a:hover {
    color: #464646;
}

.footer-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.version:after {
    content: "•";
    padding-left: 8px;
}

.credits {
    display: flex;
    background: none;
    border: none;
    cursor: pointer;
    padding-left: 10px;
}

.credits:hover {
    color: #464646;
}

@media all and (max-width: 375px) {
    .winter, .spring, .summer, .default {
        height: 300px;
    }

    .main-content .current-temp {
        font-size: 90px;
        font-weight: 800;
        letter-spacing: -6px;
        color: #FFF;
    }

    .main-content .after-dot {
        font-weight: 500;
        color: #EFD5D5;
        font-size: 64px;
        margin-left: 5px;
    }
}

@media all and (min-width: 376px) and (max-width: 468px) {
    .main-content .celcius {
        font-weight: 500;
        color: #FFF;
        opacity: 0.50;
        font-size: 20px;
    }

    .main-content .current-temp {
        font-size: 130px;
        font-weight: 800;
        letter-spacing: -6px;
    }

    .main-content .after-dot {
        font-weight: 500;
        color: #EFD5D5;
        font-size: 64px;
        margin-left: 5px;
    }
}

@media all and (max-width: 768px) {
    .main-content {
        width: 100%;
    }

    .location {
        font-size: 30px;
        letter-spacing: -1px;
        text-align: left;
    }

    .winter, .spring, .summer, .default {
        width: 100%;
        height: auto;
    }

    .main-content .current-temp {
        font-size: 100px;
    }

    .detail-widget {
        width: calc(50% - 24px);
    }

    .footer {
        justify-content: center;
    }

    .footer-left a {
        margin-right: 48px;
    }

    .popup-content {
        width: 100% !important;
        border-radius: 0 !important;
    }
}